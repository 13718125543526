import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { registerGrantee } from '../../redux/slices/granteeRegistrationSlice';
import validateUsername from '../../utils/validateUsername';
import { useTranslation } from 'react-i18next';


const GranteeRegistration = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const registrationStatus = useSelector((state) => state.granteeRegistration.status);
    const registrationError = useSelector((state) => state.granteeRegistration.error);

    const [selectedFile, setSelectedFile] = useState(null);
    const [granteeType, setGranteeType] = useState('');
    const [formData, setFormData] = useState({
        granteeType: '',
        name: '',
        description: '',
        username: '',
        email: '',
        website: '',
        password: '',
        confirmPassword: '',
        logo: null,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    }

    const handleFileChange = (e) => {
        const logo = e.target.files[0];
        setSelectedFile(logo ? logo.name : t('registration.noLogoChosen'));
        setFormData((prevData) => ({ ...prevData, logo }));
    };

    const handleGranteeTypeChange = (e) => {
        const selectedGranteeType = e.target.value;
        setGranteeType(selectedGranteeType);
        setFormData((prevData) => ({ ...prevData, granteeType: selectedGranteeType }));
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check the password confirmation field
        if (formData.password !== formData.confirmPassword) {
            // Passwords don't match, set an error message
            dispatch({ type: 'granteeRegistration/resetError' });
            dispatch({ type: 'granteeRegistration/registerGrantee/rejected', error: { message: 'Passwords do not match' } });
            return; // Prevent further processing
        }

        if (!validateUsername(formData.username)) {
            dispatch({ type: 'granteeRegistration/registerGrantee/rejected', error: { message: 'Invalid username' } });
            return;
        }

        const submissionData = new FormData();
        for (const key in formData) {
            submissionData.append(key, formData[key]);
        }

        try {
            const actionResult = await dispatch(registerGrantee(submissionData));
            // Check if the registration action succeeded
            if (registerGrantee.fulfilled.match(actionResult)) {
                // Registration successful, navigate to success page
                navigate('/registration-success');
            }
        } catch (error) {
            // Handle registration error
            console.error("Registration error:", error);
        }
    }

    useEffect(() => {
        // Check if registration status is 'succeeded' before clearing the form
        if (registrationStatus === 'succeeded') {
            // Clear form data and reset error message upon successful registration
            setFormData({
                granteeType: '',
                name: '',
                description: '',
                username: '',
                email: '',
                website: '',
                password: '',
                confirmPassword: '',
                logo: null,
            });

            // Reset error message
            dispatch({ type: 'registration/resetError' });
        } 

    }, [registrationStatus, dispatch]);

    return (
        <div className="container-fluid">
            {/* Success message */}
            {/* {registrationStatus === 'succeeded' && <div className="alert alert-success">Registration successful!</div>} */}
            <div className="row mt-5 pt-5 justify-content-center">
                <div className="col-lg-6">
                    <h2 className="text-center">{t('registration.registerAsGrantee')}</h2>
                    <p className="text-center">{t('registration.registerSearchSponsorship')}</p>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <select 
                                className="form-select" 
                                aria-label="Select the grantee type" 
                                value={granteeType} 
                                onChange={handleGranteeTypeChange}
                                required
                            >
                                <option value="" disabled >{t('registration.selectGranteeType')}</option>
                                <option value="individual">{t('registration.individual')}</option>
                                <option value="organization">{t('registration.organization')}</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">{t('registration.name')}</label>
                            <input
                                className="form-control"
                                type="text"
                                id="name"
                                name="name"
                                autoComplete="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                        {granteeType === 'organization' ? (
                            <label className="form-label" htmlFor="description">{t('registration.description')}</label>
                        ):(
                            <label className="form-label" htmlFor="username">{t('registration.bio')}</label>

                        )}
                            <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                autoComplete="description"
                                value={formData.description}
                                onChange={handleChange}
                                required
                                rows="4"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="username">{t('registration.username')}</label>
                            <input
                                className="form-control"
                                type="text"
                                id="username"
                                name="username"
                                autoComplete="username"
                                value={formData.username}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="email">{t('registration.email')}</label>
                            <input
                                className="form-control"
                                type="email"
                                id="email"
                                name="email"
                                autoComplete="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        {granteeType === 'organization' && (
                        <div className="mb-3">
                            <label className="form-label" htmlFor="website">{t('registration.website')}</label>
                            <input
                                className="form-control"
                                type="website"
                                id="website"
                                name="website"
                                autoComplete="website"
                                value={formData.website}
                                onChange={handleChange}
                                placeholder="https://example.com"
                                pattern="https?://.+"
                                title="Include http:// or https://"
                            />
                        </div>
                        )}
                        <div className="mb-3">
                            {granteeType === 'organization' ? (
                                <label className="form-label" htmlFor="logo">{t('registration.logo')}</label>
                            ):(
                                <label className="form-label" htmlFor="logo">{t('registration.profilePicture')}</label>
                            )}
                            <div className="custom-file-input">
                                <input
                                    className="form-control"
                                    type="file"
                                    id="logo"
                                    name="logo"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                />
                                <label className="form-control" htmlFor="logo">
                                {granteeType === 'organization' ? (
                                    selectedFile || t('registration.chooseLogo')
                                ):(
                                    selectedFile || t('registration.chooseProfilePicture')

                                )}
                                </label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="password">{t('registration.password')}</label>
                            <input
                                className="form-control"
                                type="password"
                                id="password"
                                name="password"
                                autoComplete="current-password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="confirmPassword">{t('registration.confirmPassword')}</label>
                            <input
                                className="form-control"
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                autoComplete="current-password"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="tos"
                                    name="tos"
                                    required
                                />
                                <label className="form-check-label" htmlFor="tos">
                                    {t('registration.registrationAcknowledge')} <Link to="/terms-of-service"> {t('registration.tos')}.</Link>
                                </label>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary w-100" disabled={registrationStatus === 'loading'}>
                            {registrationStatus === 'loading' ? 'Registering...' : t('registration.register')}
                        </button>
                        {registrationError && <div className="text-danger mt-3">{registrationError}</div>}
                    </form>   
                </div>
            </div>    
        </div>
    );
};

export default GranteeRegistration;