import authenticationReducer from './slices/authenticationSlice';
import sponsorRegistrationReducer from './slices/sponsorRegistrationSlice';
import granteeRegistrationReducer from './slices/granteeRegistrationSlice';
import createProjectReducer from './slices/createProjectSlice';
import projectsReducer from './slices/projectsSlice';
import projectReducer from './slices/projectSlice';
import projectsByUserIdReducer from './slices/projectsByUserIdSlice';
import projectsBySponsorIdReducer from './slices/projectsBySponsorIdSlice';
import createPlanReducer from './slices/createPlanSlice';
import plansReducer from './slices/plansSlice';
import plansByProjectIdReducer from './slices/plansByProjectIdSlice';
import sponsorsReducer from './slices/sponsorsSlice';
import sponsorReducer from './slices/sponsorSlice';
import sponsorsByProjectIdReducer from './slices/sponsorsByProjectIdSlice';
import createCheckoutSessionReducer from './slices/createCheckoutSessionSlice';
import ordersReducer from './slices/ordersSlice';
import ordersByUserIdReducer from './slices/ordersByUserIdSlice';
import updateCancelAtStripeSubscriptionReducer from './slices/updateCancelAtStripeSubscriptionSlice';
import createStripeBillingPortalReducer from './slices/createStripeBillingPortalSlice';
import generateQrCodeReducer from './slices/generateQrCodeSlice';
import qrCodesReducer from './slices/qrCodesSlice';
import scanQrCodeReducer from './slices/scanQrCodeSlice';
import createVoucherReducer from './slices/createVoucherSlice';
import vouchersReducer from './slices/vouchersSlice';
import voucherReducer from './slices/voucherSlice';
import redeemVoucherReducer from './slices/redeemVoucherSlice';
import voucherRedemptionsByUserIdReducer from './slices/voucherRedemptionsByUserIdSlice';
import approveVoucherRedemptionReducer from './slices/approveVoucherRedemptionSlice';
import rejectVoucherRedemptionReducer from './slices/rejectVoucherRedemptionSlice';



const rootReducer = {
    authentication: authenticationReducer,
    sponsorRegistration: sponsorRegistrationReducer,
    granteeRegistration: granteeRegistrationReducer,
    createProject: createProjectReducer,
    projects: projectsReducer,
    project: projectReducer,
    projectsByUserId: projectsByUserIdReducer,
    projectsBySponsorId: projectsBySponsorIdReducer,
    createPlan: createPlanReducer,
    plans: plansReducer,
    plansByProjectId: plansByProjectIdReducer,
    sponsors: sponsorsReducer,
    sponsor: sponsorReducer,
    sponsorsByProjectId: sponsorsByProjectIdReducer,
    createCheckoutSession: createCheckoutSessionReducer,
    orders: ordersReducer,
    ordersByUserId: ordersByUserIdReducer,
    stripeSubscription: updateCancelAtStripeSubscriptionReducer,
    createStripeBillingPortal: createStripeBillingPortalReducer,
    generateQrCode: generateQrCodeReducer,
    qrCodes: qrCodesReducer,
    scanQrCode: scanQrCodeReducer,
    createVoucher: createVoucherReducer,
    vouchers: vouchersReducer,
    voucher: voucherReducer,
    redeemVoucher: redeemVoucherReducer,
    voucherRedemptionsByUserId: voucherRedemptionsByUserIdReducer,
    approveVoucherRedemption: approveVoucherRedemptionReducer,
    rejectVoucherRedemption: rejectVoucherRedemptionReducer
}

export default rootReducer;